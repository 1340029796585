/*
 * Base structure
 */

.ellipsis {
    text-overflow: ellipsis;
    overflow-x: hidden;
}

body {
    padding-bottom: 90px;

    --danger-color: #a94442;
    --danger-bgcolor: #f2dede;
    --danger-bordercolor: #ebccd1;

    --warning-color: #8a6d3b;
    --warning-bgcolor: #fcf8e3;
    --warning-bordercolor: #faebcc;

    --footer-bg-color: #f5f5f5;
}

a:active,
a:focus,
a:hover,
a:link,
button.btn-link:focus,
button.btn-link:focus-visible,
button.btn-link:hover {
    text-decoration: none;
    outline: none;
}

a.thumbnail {
    border: 0px;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
    border: 0;
}

#nav-top a,
#nav-top button.btn-link {
    color: #fff;
}

#nav-top a.select2-choice {
    color: #555;
    height: 35px;
}

#nav-top a.select2-choice {
    line-height: 35px;
}

#nav-top .open a,
#nav-top .open button.btn-link {
    color: #777;
}

#nav-top .dropdown-menu a,
#nav-top .dropdown-menu button.btn-link {
    color: #999;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h4 {
    font-size: 16px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}

.footer {
    background-color: #f5f5f5;
    bottom: 0;
    height: 60px;
    position: fixed;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 0 10px;
    z-index: 999;
}

.sidebar-nav {
    padding: 9px 0;
}

.dropdown-hover-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
}

.dropdown-hover-menu li:hover .sub-menu {
    visibility: visible;
}

.dropdown:hover .dropdown-hover-menu {
    display: block;
}

.nav-tabs .dropdown-hover-menu,
.nav-pills .dropdown-hover-menu,
.navbar .dropdown-hover-menu {
    margin-top: 0;
}

#form-search {
    padding-top: 4px;
}

.box-categorias:after {
    content: 'Sub-categorias';
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px 0;
    color: #9da0a4;
    font-size: 12px;
    font-weight: bold;
    left: -1px;
    padding: 3px 7px;
    position: absolute;
    top: -1px;
}

.box-categorias {
    padding: 30px 19px 1px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 15px;
    position: relative;
}

.box-categorias ul {
    list-style: none outside none;
    margin: 0 0 10px;
    text-align: center;
}

.box-categorias ul li {
    display: inline;
    padding: 0 10px;
}

.breadcrumb {
    margin-bottom: 0;
}

.bxImgProdutoPromocao {
    position: absolute;
    background-repeat: no-repeat;
    background-position: top right;
    margin-top: 5px;
    margin-left: 145px;
}

.navbar .vdivider {
    height: 40px;
    margin: 5px 6px;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #ffffff;
}

.popover {
    color: #333;
}

.bx-controls-direction a {
    z-index: 0 !important;
}

.bx-wrapper {
    margin: 0 auto 0;
}

@media (min-width: 992px) {
    .bx-wrapper .bx-next {
        transform: rotate(90deg);
    }

    .bx-wrapper .bx-prev {
        transform: rotate(90deg);
    }

    .bx-wrapper .bx-controls-direction a.bx-prev {
        top: 5%;
        left: 40%;
    }

    .bx-wrapper .bx-controls-direction a.bx-next {
        top: 95%;
        right: 40%;
    }
}

.table-carrinho > tbody > tr > td {
    vertical-align: middle;
}

.table-carrinho .thumbnail {
    margin: 0;
}

.nav.navbar-nav li {
    display: inline-block;
}

.tabbable .tab-content {
    background-color: #fff;
    padding: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #dddddd;
    border-top: 0;
}

.input-group .select2-container .select2-choice {
    border-radius: 0 4px 4px 0;
}
.input-group .select2-container.select2-dropdown-open .select2-choice {
    border-radius: 0 4px 0 0;
}

.input-group > *:not(.tooltip) .btn:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.produtos-grid {
    display: inline-block;
    margin-top: 30px;
}

.produtos-grid-item {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 50px;
}

.produtos-grid-item .thumbnail {
    width: 180px;
    height: 180px;
    box-shadow: none;
}

.table tr.sub-detail td,
.table tr.detail td {
    border-top: 0;
    padding-top: 0;
}

.table tr.sub-detail td {
    font-size: 95%;
}

.sub-detail .label {
    font-size: 85%;
}

.panel-title {
    font-weight: 400;
}

.panel-default {
    border-color: #dedede;
}

.panel-default .panel-heading {
    color: #525252;
    background: #e6e6e6;
}

.panel-body.row {
    padding-bottom: 0;
}

.btn {
    border: 1px solid transparent;
    transition-duration: 0.3s;
    transition-property: border-color, background-color, color;
}

.select2-choice {
    background: #ffffff !important;
}

.select2-choice .select2-arrow {
    background: #ffffff !important;
}

.navbar-nav .open .dropdown-menu {
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 0;
    background-color: #fff;
    position: absolute;
}

.nav.navbar-nav .dropdown-menu li {
    width: 100%;
}

.nav.navbar-nav .dropdown-menu .dropdown-header {
    font-size: 14px;
    line-height: 2;
    font-weight: bold;
}

.nav.navbar-nav .dropdown-menu > li > a,
.nav.navbar-nav .dropdown-menu > li > button.btn-link {
    padding: 5px 20px;
}

.dropdown-menu > li > a,
.dropdown-menu > li > button.btn-link {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
    width: 100%;
    text-align: left;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > button.btn-link:hover,
.dropdown-menu > li > button.btn-link:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.dropdown-menu .divider {
    margin: 4px 0px;
}

.btn-default {
    border-color: #ccc;
}

.no-break {
    white-space: nowrap;
}

.list-group.list-group-table .list-group-item.active {
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
}

.list-group.list-group-table .list-group-item:not(.header):hover,
.list-group.list-group-table .list-group-item:not(.header):focus {
    background-color: #ededed;
}

:root {
    --col-input-radio-padding: 40px;
}
.list-group.list-group-table .list-group-item .col-input-radio {
    padding-left: var(--col-input-radio-padding);
}

@media (max-width: 767px) {
    .list-group.list-group-table .list-group-item .col-input-radio-xs {
        padding-left: var(--col-input-radio-padding);
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .list-group.list-group-table .list-group-item .col-input-radio-sm {
        padding-left: var(--col-input-radio-padding);
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .list-group.list-group-table .list-group-item .col-input-radio-md {
        padding-left: var(--col-input-radio-padding);
    }
}
@media (min-width: 1200px) {
    .list-group.list-group-table .list-group-item .col-input-radio-lg {
        padding-left: var(--col-input-radio-padding);
    }
}

.list-group.list-group-table .list-group-item .col-input-radio input[type] {
    margin-left: -25px;
    float: left;
}

.list-group.list-group-table .list-group-item,
.list-group.list-group-table .list-group-item.active {
    border-left: 0;
    border-right: 0;
}

.list-group.list-group-table .list-group-item:last-child {
    border-bottom: 0;
}

.panel-heading ~ .list-group .list-group-item.header,
.list-group-table .list-group-item.header {
    border-top-width: 0;
}

.list-group .list-group-item.header {
    border-bottom: 2px solid #ddd;
    font-weight: 700;
}

.list-group .list-group-item.header.align-bottom > .row {
    display: flex;
    align-items: end;
}

.list-group-item .row.padder.empty-list,
.list-group-item.empty-list,
table tr.empty-list td {
    text-align: center;
    padding: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.panel-body.list-group {
    padding: 0 15px;
}

.panel .list-group.list-group-bordered .list-group-item.header {
    border-width: 1px 1px 2px 1px;
    border-radius: 4px 4px 0 0;
}

.panel .list-group.list-group-bordered .list-group-item {
    border-width: 1px;
}

.panel .list-group.list-group-bordered .list-group-item:last-child {
    border-radius: 0px 0px 4px 4px;
    border-bottom: 1px solid #ddd;
}

.status-progression {
    text-align: center;
    padding: 0 0 20px 0;
}

.status-progression .btn {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .full-width-button-bar-md .btn:first {
        margin-top: 0;
    }

    .full-width-button-bar-md .btn {
        width: 100%;
        margin-top: 1rem;
    }

    .btn.btn-full-width-xs {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .full-width-button-bar-lg .btn:first {
        margin-top: 0;
    }

    .full-width-button-bar-lg .btn {
        width: 100%;
        margin-top: 1rem;
    }
}

@media (min-width: 992px) {
    .text-right-md {
        text-align: right;
    }
}

@media (min-width: 768px) {
    .text-right-responsive {
        text-align: right;
    }

    .text-right-responsive * {
        text-align: left;
    }
}

@keyframes rotate-icon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.load-feedback.disabled.clicked .load-feedback-icon-default,
.load-feedback .load-feedback-icon {
    display: none;
}

.load-feedback.disabled.clicked .load-feedback-icon,
.rotate-icon {
    animation: rotate-icon 1.5s linear infinite;
    display: inline-block;
}

@media screen and (max-width: 480px) {
    h2.page-header {
        font-size: 25px;
    }
}

.page-header span.glyphicon,
.panel-title span.glyphicon {
    vertical-align: text-bottom;
}

.ofuscated {
    z-index: 1040;
}

.form-horizontal.page-header .form-group {
    margin-bottom: 0;
}

.nl2br {
    white-space: pre-line;
}

.alert-warning {
    border-color: #cfc6b3 !important;
}

.animating-icon {
    animation: rotate-icon 1.5s linear infinite;
}

.tr-address {
    border-top: none;
}

.tr-address td {
    border-top: none !important;
    padding-bottom: 30px !important;
    padding-left: 38px !important;
}

.turbo-progress-bar {
    height: 5px;
    background-color: #8a0000;
    background: linear-gradient(to right, #8a0000 75%, red);
}

.modal-title-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1ex;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tr-sub-row td,
.tr-sub-row th {
    padding: 8px 8px 0px !important;
}

.table-deposit-row td {
    border: none !important;
}

.table-deposit-row td:last-child {
    padding: 0px 0px 8px 8px;
}

/** remove 3d datepicker */
.datepicker table tr td.active {
    background-image: none !important;
}

.dropdown-notifications {
    width: 320px;
}

.dropdown-notifications .notification-title {
    margin-top: 0px;
    padding: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    display: block;
    box-shadow: 0 3px 5px -7px #333;
}

.dropdown-notifications .notifications-body {
    display: block;
    position: relative;
    max-height: 340px;
    overflow: hidden;
}

.dropdown-notifications .notify-item {
    display: block;
    padding: 12px 20px;
    border-top: 1px solid #dadada;
}

.dropdown-notifications .notify-item:hover {
    background-color: #f8f8f8;
}

.dropdown-notifications .notify-item:hover .remove-item {
    display: inline-block;
}

.dropdown-notifications .notify-item .notify-title {
    color: #333;
    font-size: 15px;
    width: 260px;
    display: inline-block;
}

.dropdown-notifications .notify-item .notify-title:before {
    content: '\25cf';
    font-size: 1.2em;
    color: #ce0000;
    margin-right: 5px;
    top: 1px;
    position: relative;
}

.dropdown-notifications .notify-item .notify-description {
    color: #777;
}

.dropdown-notifications .notify-item .remove-item {
    color: #e0e0df;
    display: block;
    top: 6px;
    position: relative;
    float: right;
}

.dropdown-notifications .notify-item .remove-item:hover {
    color: #777;
}

.dropdown-notifications .notify-item .highlight {
    color: #0077cc;
    text-align: right;
    display: block;
}

.dropdown-notifications .notify-item:hover .highlight {
    text-decoration: underline;
}

#notification-area.dropdown-notifications .notify-all {
    display: block;
    padding: 12px 20px;
    border-top: 1px solid #dadada;
    text-align: center;
    color: #777;
    font-weight: bold;
}

#notification-area.dropdown-notifications .notify-all:hover {
    background-color: #f8f8f8;
}

/** produto **/
@keyframes spin-anim {
    from {
        transform: rotate(0deg) translate(-0.25em) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate(-0.25em) rotate(-360deg);
    }
}

.sppining {
    animation: spin-anim 1.5s infinite linear;
    margin: 0.25em;
}

@keyframes load-anim {
    0% {
    }

    50% {
        border-color: #8a6d3b;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #c0a16b;
    }

    100% {
    }
}

.form-control.is-loading {
    animation: load-anim 1.5s infinite linear;
}

#tabConsultarDisponibilidade {
    text-align: left;
}

#resultadoConsultarDisponibilidade {
    font-size: 1.8rem;
    font-weight: bold;
}

#resultadoConsultarDisponibilidadeTable,
#resultadoConsultarDisponibilidade {
    margin-top: 2rem;
}

.text-right {
    text-align: right;
}

@media screen and (min-width: 480px) {
    #consultarDisponibilidade .controls {
        display: flex;
        align-items: flex-end;
    }

    #consultarDisponibilidade .form-group {
        padding: 0 0.5rem;
    }

    #quantidadeConsultarDisponibilidade {
        width: 8rem;
    }
}

.flex-row-end {
    display: flex;
    align-items: flex-end;
}

.flex-column-end {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-row-justify-end {
    display: flex;
    justify-content: end;
}

.flex-row-align-items-center {
    display: flex;
    align-items: center;
}

.container-link-pagamento .panel .panel-heading[data-toggle='collapse'] {
    cursor: pointer;
}

@media screen and (min-width: 992px) {
    .container-link-pagamento .panel .column-number > div {
        float: right;
    }

    .container-link-pagamento .row.full-width-button-bar-lg,
    .container-link-pagamento .row.full-width-button-bar-lg .box-button,
    .container-link-pagamento .row.full-width-button-bar-lg .box-button a {
        display: flex;
    }

    .container-link-pagamento .row.full-width-button-bar-lg .box-button a {
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
}

.box-item {
    float: left;
    width: 100%;
    background: #ededed;
    border-radius: 10px;
    margin: 5px 0;
    padding: 10px 0;
}

.box-item .label-row {
    padding-bottom: 1rem;
}

.box-pagamento {
    border-radius: 10px;
    margin: 5px 15px;
    padding: 10px;
}

.box-button {
    margin-top: 15px;
    margin-bottom: 15px;
}

.icon-whatsapp {
    border: solid 2px;
    border-radius: 50%;
    font-size: 10px;
    padding: 2px;
}

.icon-whatsapp::after {
    bottom: -2px;
    left: -2px;
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-top-right-radius: 100%;
    background: #ffffff;
}

#painelTratamento .navbar-header {
    width: 100%;
}

#painelTratamento {
    background: #ffffff;
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    min-height: 100vh;
    bottom: 0;
    transition: 0.5s;
    z-index: 1000;
}

#painelTratamento .containerTratamento {
    max-height: calc(100vh - 60px);
    padding-bottom: 20px;
    overflow: auto;
}

body.painelTratamentoAberto #painelTratamento {
    left: 0vw;
}

body.painelTratamentoAberto {
    overflow: hidden;
}

#btnFecharPainelTratamento {
    margin-top: 10px;
}

.btn small {
    white-space: normal;
}

#nav-top.nav-link-pagamento {
    margin-bottom: 15px;
}

@media (max-width: 480px) {
    .input-group-responsive .input-group-addon,
    .input-group-responsive .input-group-btn,
    .input-group-responsive .input-group,
    .input-group-responsive .form-control {
        display: block !important;
        margin-bottom: 10px;
        clear: both;
    }

    .input-group-responsive {
        display: block !important;
    }

    #pagamentos #btnAprovarPedido {
        margin-bottom: 60px;
    }

    #pagamentos #nav-top .navbar-brand {
        padding: 15px 0;
    }

    #pagamentos #nav-top .navbar-brand img {
        height: 27px !important;
    }
}

input[readonly].copy-target {
    background-color: #fff;
}

.lista-responsiva-item {
    padding: 5px 15px;
}

@media (max-width: 768px) {
    .lista-responsiva-item {
        text-align: left;
    }

    .lista-responsiva-item strong {
        display: inline-block;
    }
}

@media (max-width: 992px) {
    .form-control-static-responsive {
        min-height: 34px;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-bottom: 0;
    }
}

/* custom colored buttons */
.btn-darkcyan {
    background-color: rgb(0, 109, 117);
    border-color: rgb(0, 107, 117);
    color: white;
}

.btn-darkcyan:hover,
.btn-darkcyan:focus {
    background-color: rgb(0, 89, 97);
    border-color: rgb(0, 59, 67);
    color: white;
}

.btn-darkcyan:active {
    background-color: rgb(0, 69, 77);
    border-color: rgb(0, 49, 57);
    color: white;
}

.btn-darkyellow {
    background-color: rgb(199, 152, 0);
    border-color: rgb(199, 152, 0);
    color: white;
}

.btn-darkyellow:hover,
.btn-darkyellow:focus {
    background-color: rgb(169, 122, 0);
    border-color: rgb(139, 82, 0);
    color: white;
}

.btn-darkyellow:active {
    background-color: rgb(149, 102, 0);
    border-color: rgb(139, 82, 0);
    color: white;
}

.no-margin {
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

@keyframes highlight {
    10% {
        box-shadow: 0 0 3px 3px #5bc0de;
    }

    50% {
        box-shadow: none;
    }
}

.highlighted {
    animation: highlight 3s;
}

.login-content .panel-body button[type="submit"] {
    margin-bottom: 10px;
    width: 100%;
}

.parcela-bloqueada {
    background-color: #D4D4D4;
    color: #992E2E;
}

.fully-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
